import React,{useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../../assets/Images/logo/Logo.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Dsicon from '../../../assets/Images/dsicon.webp';
import './Header.css';
import { LoginContext } from '../../../LoginContext';


function Header() {
    const { LoggedInData, login, logout } = useContext(LoginContext);
    const logoutUser = () => {
        logout();
        window.location.href = "/";
    }
    return (
        <div className="App">
            <Navbar expand="lg" className='header-custom dashboard-header-bg'>
                <Container fluid className='dashboard-custom-container Loged-in-user'>
                    <Navbar.Brand href="/Dashboard">
                        <img src={Logo} alt="Google" className='logo-image' />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav" className='dashboard-collapse'>
                        <Nav className="ms-auto align-items-center">
                            <Nav.Link href='#' className='nav-menu'>
                                <div className='notification-btn'>
                                    <FontAwesomeIcon icon={faBell} />
                                </div>
                            </Nav.Link>
                            {
                                LoggedInData != null  ? (
                            <NavDropdown className='nav-menu dash-drop' title={`${LoggedInData.first_name} ${LoggedInData.last_name} `} id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/Dashboard" className='nav-menu'><img src={Dsicon} alt='###' className='icn-width'/> <span className='ps-1'>Dashboard</span></NavDropdown.Item>
                                    <NavDropdown.Item className='nav-menu'><FontAwesomeIcon icon={faPowerOff} /><span className='ps-2'onClick={logoutUser} >Logout</span></NavDropdown.Item>
                            </NavDropdown> ) :
                            (
                                <></>
                            )
                                
                            }

                            


                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;