import axios from "axios";
import { API_URL } from "./config.js";
import { apiCall } from "./auth";

//getservicesdata
export const getbookingservices = () => apiCall(`${API_URL}getfrontbookingserviceslist`, "GET",);

//getsubservicebyserives
export const getsubservicebyservice = (formData) => apiCall(`${API_URL}getsubservicebyservice`, "POST", formData);

//getstafflist 
export const getstafflist = () => apiCall(`${API_URL}getstafflist`, "GET",);

//getstafflist 
export const getstaffTime = (date, staffid) => apiCall(`${API_URL}getbookings?start_date=${date}&staffid=${staffid}`, "GET",);

//getaddon 
export const getaddon = (formData) => apiCall(`${API_URL}getaddonbyaservice`, "POST", formData);

//getuser 
export const getuser = (formData) => apiCall(`${API_URL}frontlogin`, "POST", formData);

//getuser 
export const SignUp = (formData) => apiCall(`${API_URL}signup`, "POST", formData);

//getuser 
export const addbooking = (formData) => apiCall(`${API_URL}addbooking`, "POST", formData);
