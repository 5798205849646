import React from "react";
import Header from './camponent/header/Header';
import './assets/css/Product.css';
import Leftimage from "./camponent/product/Leftimage";
import Rightimage from "./camponent/product/Rightimage";
import Testimonial from "./camponent/Testimonials/Testimonials";
import Miele from './assets/Images/product/miele.webp';
import Vacuum from './assets/Images/product/vacuum.webp';
import Cloth from './assets/Images/product/cloth.webp';
import Bonami from './assets/Images/product/bon ami.webp';
import Citrasolv from './assets/Images/product/citrasolv.webp';
import Simlegreend from './assets/Images/product/simle green d.webp';
import Redjuice from './assets/Images/product/red juice.webp';
import Floorcleaner from './assets/Images/product/floor cleaner.webp';
import Middle from './assets/Images/product/middle.webp';
import Usercomment from "./camponent/Testimonials/Usercomment";
import Profile from './assets/Images/profile.jpg';
import Footer from './camponent/footer/Footer';
import Social from './camponent/social/Social';
import Star from './assets/Images/star.webp';
import Header2 from "./camponent/header/Header2";
import Comman from "./camponent/CommanContent/Comman";

function Ourproduct() {
    return (
        <div className="ourproduct">
            <div className="Header">
                <Header2 />
            </div>
            <div className="social">
                <Social />
            </div>

            {/* hero section start here  */}
            <div className="our-product-hero custom-section" id="our-product-hero">
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="hero-image">
                                <div className="hero-text">
                                    <div className="bg-custom-title">
                                        <h2 className='title'>Our Cleaning <span className='bold-title'><span className='bold-title bg-color'>Products</span></span></h2>
                                        {/* <p>All of our cleaning products are chosen for their superior cleaning ability, health advantages, and eco-friendliness. These products reduce airborne particles as well as reduce or eliminate harsh chemicals from your cleaning schedule. We can’t list them all but here are a few of the best products on the market.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero section end here  */}
            <section className="about-content custom-section">
                <div className="container">
                    <Leftimage imgsrc={Miele} title={`Miele © HEPA Filters`} description={`Filters that are awarded the HEPA accolade are used in various locations, whether in medical facilities, automotive vehicles, airplanes, home filters, or wherever very pure air is sought. Our HEPA filters remove 99.97% of all particles greater than 0.3 micrometers from the air that pass through. Excellent for allergy asthma and MCS sufferers.`} />
                    <Rightimage imgsrc={Vacuum} title={`Miele © brand vacuum cleaners`} description={`The Active Clean Filter along with the AirClean bags creates an effective cleaning system that will outperform most vacuums as it captures 99.9% of all particles as small as .03 microns. We have upgraded to AH-50 HEPA filters to trap 99.99% of dust and particles outperforming many air purifiers and bringing the particle count down to almost zero!`} />
                    <Leftimage imgsrc={Cloth} title={`Microfiber Dusting Cloths`} description={`Microfiber is a hypoallergenic material that is easily cleaned between uses. Thus eliminating the possibility of bringing external allergens into your home. Another benefit is that the material is roughly 4x as effective as a standard cloth. This means that not only will it clean better, but the cloth itself will have a smaller environmental footprint due to the reduced amount of water required to launder the material.`} />
                    <Rightimage imgsrc={Bonami} title={`BON AMI`} description={`One of our favorite cleaning products is Bon Ami. It has ingredients with names you can pronounce. These simple ingredients have been the base of their cleaning powder since it was first created in 1886. Why ruin a great thing?`} />
                    <Leftimage imgsrc={Citrasolv} title={`Citra Solv Natural Cleaner & Degreaser`} description={`The cleaning product that started it all! We can’t get enough of this versatile citrus solvent. We love it for its hundreds of uses, its ability to clean even the toughest stains, and its fresh orange fragrance.`} />
                    <Rightimage imgsrc={Simlegreend} title={`Simple Green Pro 5`} description={`Simple Green Pro 5 is one of the most effective green disinfectants on the market. This formula is multipurpose as it cleans, deodorizes, and disinfects. It can also prevent the growth of mold and mildew on a variety of surfaces. This product is EPA registered so it is not only high performing but it is safe.`} />
                    <Leftimage imgsrc={Redjuice} title={`Red Juice`} description={`An alternative to our citrus cleaner for those who prefer little to no scent cleaning. Red Juice is all-natural with ingredients such as soybean extracts, palm kernels, and palm oils. Red Juice is pH neutral, non-toxic, unscented, and completely biodegradable.`} />
                    <Rightimage imgsrc={Floorcleaner} title={`Medline pH Neutral Floor Cleaner`} description={`This amazing solution is a sure way to make your floors shine. It is specifically formulated with a neutral pH so it can be used daily without dulling finishes. The Neutral pH cuts through and loosens dirt without compromising floor finishes. It is a favorite because it leaves no soapy residue on your freshly cleaned floors. This product is Green Seal Certified.`} />
                    <Leftimage imgsrc={Middle} title={`Sh-mop`} description={`Not your average mop. Unlike ordinary mops, the Sh-Base has a very low profile and pivots in any direction to allow our techs to quickly and easily clean into corners, along with tough angles, and underneath tight spots without splashing cleaning products on baseboards. The removable washable terry cloth cover ensures that you get a sanitary mop head with every clean`} />
                </div>
            </section>

            {/* section end here  */}

            {/* testimonial section start here */}
            <section className="custom-section">
                <div className="container">
                    <Testimonial ratingimage={Star} sectiontitle={'The #1 Rated Cleaning Company In San Diego!'} sectiondescription={'Find out why Green Frog Cleaning is San Diego’s favorite cleaning company...'} />
                    <div className="row">
                        <div className="col-md-6">
                            <Usercomment imagesrc={Profile} username={'Andrea L.'} userdiscription1={'We’ve been with Green Frog for a while and have been happy with our housekeeping services. I found them on Yelp and they responded through the app to my message. They always respond to my emails and answer the phone. '} heighlight={'The staff here is awesome!'} />
                        </div>
                        <div className="col-md-6">
                            <Usercomment imagesrc={Profile} username={'Sarah B.'} heighlight={'I love Green Frog! '} userdiscription2={' I can not believe how thorough the initial cleaning was. It was fantastic. I have had my house cleaned by a few different services - no one has cleaned as well as Green Frog. I love that they use natural products, too.'} />
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial section end here */}

            <Comman />
            
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default Ourproduct;