import React, { useState,useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Profile.css';
import Angi from '../../../assets/Images/social/angi.webp';
import Facebook from '../../../assets/Images/social/facebook.png';
import Nextdoor from '../../../assets/Images/social/nextdoor.webp';
import Yelp from '../../../assets/Images/social/yelp.webp';
import User from '../../../assets/Images/User.webp';
import Userback from '../../../assets/Images/back.webp';
import { BsArrowRepeat } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { LoginContext } from '../../../LoginContext';
function Profile2() {
    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [validated, setValidated] = useState(false);
    const[firstName,setFirstName]=useState('nihal')
    const[lastName,setLastName]=useState('bhardwaj')
    const [email, setemail] = useState('email');
    const [password, setpassword] = useState('password');
    const[number,setNumber]=useState('number')
    const [streetAddress, setStreetAddress] = useState('address');
    const [city, setcity] = useState('city');
    const [state, setState] = useState('state');
    const [zipCode, setZipCode] = useState('zip');
    const [specialInstructions, setSpecialInstructions] = useState('country');

    const handleSubmit = async (event) => {
        event.preventDefault();
    };

    return (

        <>
            {/* Edit Profile Modal start here  */}
            <div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Information::::</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom01">
                                        <Form.Label className='custom-lable'>first name</Form.Label>
                                        <Form.Control required type="name" value={firstName} onChange={(e) => setemail(e.target.value)} placeholder="John Deo" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom02">
                                        <Form.Label className='custom-lable'>last name::::::</Form.Label>
                                        <Form.Control required type="email" value={lastName} onChange={(e) => setemail(e.target.value)} placeholder="john.doe@gmail.com" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your email::::::
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom03">
                                        <Form.Label className='custom-lable'>email address</Form.Label>
                                        <Form.Control required type="password" value={email} onChange={(e) => setpassword(e.target.value)} placeholder="********" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your password
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom04">
                                        <Form.Label className='custom-lable'>Number</Form.Label>
                                        <Form.Control required type="number" value={number} onChange={(e) => setemail(e.target.value)} placeholder="457-456-6794" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom05">
                                        <Form.Label className='custom-lable'>Street Address</Form.Label>
                                        <Form.Control required type="text" value={streetAddress} onChange={(e) => setpassword(e.target.value)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom06">
                                        <Form.Label className='custom-lable'>Zip Code</Form.Label>
                                        <Form.Control required type="number" value={zipCode} onChange={(e) => setpassword(e.target.value)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom07">
                                        <Form.Label className='custom-lable'>City</Form.Label>
                                        <Form.Control required type="text" value={city} onChange={(e) => setemail(e.target.value)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom08">
                                        <Form.Label className='custom-lable'>State</Form.Label>
                                        <Form.Control required type="text" value={state} onChange={(e) => setpassword(e.target.value)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom09">
                                        <Form.Label className='custom-lable'>Special Instructions</Form.Label>
                                        <Form.Control required type="text" value={specialInstructions} onChange={(e) => setpassword(e.target.value)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='modal-btn'>
                                    <button href="#" className='btn Active-bttn' onClick={handleClose} >Save Changes</button>
                                    <button href="#" className='btn Cancel-bttn' onClick={handleClose}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Edit Profile Modal End Here  */}

            {/* Change Password Modal Start Here */}
            <Modal show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Old Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>New Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Confirm Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='modal-btn'>
                                <button href="#" className='btn Active-bttn' onClick={handleClose2} >Save Changes</button>
                                <button href="#" className='btn Cancel-bttn' onClick={handleClose2}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Change Password Modal End Here */}

            <div className="App">
                <div className="profile-show-mobile">
                    <div className="Profile-back-cover">
                        <img src={Userback} alt="Rectangle-1" />
                    </div>
                    <div className="Profile-img">
                        <img src={User} alt="Rect" className='img-fluid rounded-circle' />
                    </div>
                    <div className="Profile-name">
                        <h4>Shakil Ahmed</h4>
                    </div>
                    <div className="profile-btn">
                        <button className="btn" onClick={handleShow}> Edit Profile</button>
                        <button className="btn" onClick={handleShow2}> Change Password</button>
                    </div>
                    <div className="Profile-details">
                        <div className="email d-flex">
                            <h6 className='details-width'>Email : </h6>
                            <p>neha@gmail.com</p>
                        </div>
                        <div className="phone d-flex">
                            <h6 className='details-width'>Phone : </h6>
                            <p>01700000000</p>
                        </div>
                        <div className="address d-flex">
                            <h6 className='details-width'>Zip Code : </h6>
                            <p>1200</p>
                        </div>
                    </div>
                    <div className="Profile-card-bottom">
                        <div className="profile-bottom-card">
                            <div className="social-images-icon d-flex justify-content-between w-100">
                                <a href='#'><img src={Facebook} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Nextdoor} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Yelp} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Angi} alt="Rectangle-1" /> </a>
                            </div>
                            <div className="bottom-discription">
                                <p> Green Frog © 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Profile2;
