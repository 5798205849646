import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Header from '.././camponent/Header/Header';
import Topnavigation from '.././camponent/Topnavigation/Topnavigation';
import Herosection from '.././camponent/Herosection/Herosection';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './Dashboard.css';
import Profile from '.././camponent/Profile/Profile';
import { BsPlusLg } from "react-icons/bs";
import Visa from '../../../src/assets/Images/social/visa.webp';
import Mastercard from '../../../src/assets/Images/social/mastercard.webp';
import Americanex from '../../../src/assets/Images/social/americanex.webp';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Profile2 from "../camponent/Profile/Profile2";
import { LoginContext } from "../../LoginContext";
import { getpaymentMethod } from "../../Api/dashboardApi";
import { deletepaymentmethod } from "../../Api/dashboardApi";
import { getstaffApi } from "../../Api/dashboardApi";
import { cancelbookingbyid } from "../../Api/dashboardApi";



function Dashboard() {
    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [bookings, setBookings] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    console.log("LoggedInData", LoggedInData);

    const [staff, setStaff] = useState([])
    
    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const [show4, setShow4] = useState(false);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [setShowText, showText] = useState(false);

    const [show6, setShow6] = useState(false);

    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    const [show7, setShow7] = useState(false);

    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);

    const [lastrecordcount, setLastRecordCount] = useState(1);
    const [singalbookingData, setSingalbookingData] = useState([]);
    


    useEffect(() => {
        if (LoggedInData != null) {
            getUserData(LoggedInData.id)
            getstaff();
        }
    }, [LoggedInData]);


    const getUserData = (uid) => {
        const formData = new FormData();
        formData.append("user_id", uid);
        formData.append("lastrecordcount", lastrecordcount);
        axios({
            method: 'POST',
            url: 'http://192.168.1.16:8080/newsystemapi/v1/api/getUserBookingfront',
            data: formData,
        })
            .then((response) => {
                console.log(response.data.data)
                setBookings(response.data.data)
                setLastRecordCount(lastrecordcount + 1);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getPayments = () => {
        const formData = new FormData();
        formData.append("customer_id", LoggedInData.customer_id);

        getpaymentMethod(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setPaymentMethods(apiResponse.data);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });
    }


    const getcardtype = (date) => {
        const month = date.slice(0, 2);
        const year = date.slice(2);
        const formattedDate = `${month}/${year}`;
        return formattedDate;
    }

    const deleteCard = (id) => {
        if(window.confirm("Are you sure you want to delete this card?")){
            const formData = new FormData();
            formData.append("id", id);
            deletepaymentmethod(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == '1') {
                        const apiResponse = res.data;
                        getPayments();

                    }
                    else {
                        console.log("Something went wrong");
                    }
    
                } else {
                    alert("Something went wrong");
                }
            });
        }
        
    }

    const getstaff = () => {
        getstaffApi().then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setStaff(apiResponse.data);
                }
                else {
                    console.log("Something went wrong");
                }

            } else {
                alert("Something went wrong");
            }
        });
    }

    const cancelbooking = (id) => {
        if(window.confirm("Are you sure you want to cancel this booking?")){
            const formData = new FormData();
            formData.append("order_id", id);
            cancelbookingbyid(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == '1') {
                        const apiResponse = res.data;
                        console.log(apiResponse);   
                    }
                    else {
                        console.log("Something went wrong");
                    }

                } else {
                    alert("Something went wrong");
                }
            });
        }

    }


    const reschedulebooking = (booking) => {
        setSingalbookingData(booking);
    }
    
    const getbookingdate = (date) => {
        if(date != null){
            const date1 = date.slice(0, 10);
            return date1;
        }
    }

    const getbookingtime = (date) => {
        if(date != null){
            const time = date.slice(11);
            return time;
        }
    }

        


    return (
        <>
            {/* Edit Card modal start here */}
            <Modal show={show3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Card Number</Form.Label>
                                <Form.Control required type="name" placeholder="**** **** **** 9856" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input' />
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>CVV</Form.Label>
                                <Form.Control required type="number" placeholder="  " className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose3} >Save Changes</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose3}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Add Card modal start here */}
            <Modal show={show4} onHide={handleClose4} centered>
                <Modal.Header closeButton>
                    <Modal.Title>New Card Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Card Number</Form.Label>
                                <Form.Control required type="name" placeholder="**** **** **** 9856" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Exp. Date</Form.Label>
                                <Form.Control required type="Text" placeholder="MM/YY" className='custom-input' />
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>CVV</Form.Label>
                                <Form.Control required type="number" placeholder="  " className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Full Name</Form.Label>
                                <Form.Control required type="text" placeholder="Full Name" className='custom-input' />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose4} >Save</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose4}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Reschedule Booking modal start here */}
            <Modal show={show5} onHide={handleClose5} centered>
                <Modal.Header closeButton>
                    <div>
                        <Modal.Title>Reschedule Booking </Modal.Title>
                        <div className=''>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Current Booking Date:</div>
                                <div className='custom-txt'>
                                    <div><h6>{getbookingdate(singalbookingData.booking_date_time)}</h6></div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Current Booking Time :</div>
                                <div className='custom-txt'>
                                    <div><h6>{getbookingtime(singalbookingData.booking_date_time)}</h6></div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='custom-lable'>Ammount :</div>
                                <div className='custom-txt'>
                                    <div><h6>{singalbookingData.amount}</h6></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="booking-custom-lable mb-4" controlId="formBasicEmail">
                                <Form.Label className='custom-lable'>Select Staff</Form.Label>
                                <Form.Select aria-label="Default select example" className='custom-input'>
                                    <option>Select Staff</option>
                                    {
                                        staff.map((staff, index) => {
                                            return (
                                                <option value={staff.id}>{staff.title}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <Form.Label className='custom-lable'>Note</Form.Label>
                                <Form.Control as="textarea" rows={3} required type="text" placeholder=" " />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-4" controlId="validationCustom01">
                                <div className='d-flex align-items-center'>
                                    <Form.Check type="switch" id="custom-switch" onClick={() => showText(!setShowText)} />
                                    <Form.Label className='custom-lable ms-2 mb-0'>Update Schedule Date & Time</Form.Label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>

                    {setShowText && <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'> Update Date & Time</Form.Label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Form.Control type="Date" placeholder=" " className='custom-input' />
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Control type="text" placeholder="10:30 AM" className='custom-input' />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </>}

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose5} >Update Appointment</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose5}>Cancel</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Cancel booking modal start here */}
            <Modal show={show6} onHide={handleClose6} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='custom-lable text-center mb-4'>Are you sure you want to cancel this booking?</div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose6}>Yes</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose6}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}

            {/* Delete Card modal start here */}
            <Modal show={show7} onHide={handleClose7} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='custom-lable text-center mb-4'>Are you sure you want to Delete this Card?</div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='modal-btn'>
                            <button href="#" className='btn Active-bttn' onClick={handleClose7}>Delete</button>
                            <button href="#" className='btn Cancel-bttn' onClick={handleClose7}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal End here */}



            <div className="App">
                <Header />
                <Topnavigation />
                <Herosection />
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="bg-custom">
                        <div className="dashboard-custom-container hero-bottom">
                            <div className='hero-bottom-margin'>
                                <div className="dashboard-tab-list">
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className='Booking-tab me-4'>Booking</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="Payment" className='Booking-tab me-4' onClick={getPayments}>Payment Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="Transaction" className='Booking-tab '>Transaction</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="hide-desktop show-mobile">
                                            <Nav.Link eventKey="Profile" className='Booking-tab ms-4'>Profile</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-custom-container dflt-mt-top">
                        <div className='row'>
                            <div className="col-xxl-3 col-xl-4">
                                <Profile />
                            </div>
                            <div className="col-xxl-9 col-xl-8 col-md-12 text-start">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="booking-list">
                                            <nav>

                                                <ul>
                                                    {
                                                        bookings.map((booking, index) => {


                                                            return (
                                                                <li>
                                                                    <div className="bookings-service-listed">

                                                                        <div className="booking-list-number">
                                                                            <h1>{index + 1}</h1>
                                                                            <div className="booking-list-content">
                                                                                <div className='order-id'>
                                                                                    <h6>{`Order ID: ${booking.order_id}`}</h6>
                                                                                </div>
                                                                                <div className='order-type'>
                                                                                    <h4>{booking.title}</h4>
                                                                                </div>
                                                                                <div className='order-text'>
                                                                                    <p>AMOUNT : {`$${booking.amount}`}</p>
                                                                                    <p>Boooking Date Time : {`${booking.booking_date_time}`}</p>
                                                                                </div>
                                                                                <div className='order-text'>
                                                                                    <p>Notes : {booking.app_notes}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="booking-actions">
                                                                            <button href="#" className='btn mb-3 Active-bttn' onClick={
                                                                                (e) => {
                                                                                    handleShow5();
                                                                                    reschedulebooking(booking);
                                                                                }
                                                                            } >Reschedule Booking</button>
                                                                            <button href="#" className='btn mb-3 Cancel-bttn' onClick={
                                                                                () => {
                                                                                    cancelbooking(booking.order_id);
                                                                                }
                                                                            } >Cancel Booking</button>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            
                                                            )

                                                        })}



                                                </ul>
                                                
                                                <nav aria-label="Page navigation example"  >
                                                    load more
                                                </nav>
                                            </nav>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Payment">
                                        <div className="payment-settings">
                                            <div className="payment-settings-content">
                                                <div className="payment-settings-content-header d-flex justify-content-beetwin align-items-center">
                                                    <h3>Payment Methods</h3>
                                                    <button href="#" className='btn Add-bttn ms-auto' onClick={handleShow4} ><span><BsPlusLg /></span> Add Card</button>
                                                </div>
                                                <div className="payment-settings-content-body mt-4">
                                                    <nav>
                                                        <ul>
                                                            {
                                                                paymentMethods.map((payment, index) => {
                                                                    return (
                                                                        <li>
                                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                                <div className="Payment-list-content">
                                                                                    {/* <div className='Card-type-img'>
                                                                            <img src={payment.card_icon} alt="***" />
                                                                        </div> */}
                                                                                    <div className='Card-Name'>
                                                                                        <h4>{payment.person_name}</h4>
                                                                                    </div>
                                                                                    <div className='Card-Name'>
                                                                                        <h4>{payment.card_type}</h4>
                                                                                    </div>
                                                                                    <div className='Card-number'>
                                                                                        <p>{payment.card_last_four}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="booking-actions">
                                                                                    <div className='d-flex'>
                                                                                        <button href="#" className='btn Edit-bttn me-3' onClick={
                                                                                            () => {
                                                                                                handleShow3();
                                                                                            }
                                                                                        } >Edit</button>
                                                                                        <button href="#" className='btn Delete-bttn' onClick={
                                                                                            () => {
                                                                                                // handleShow7();
                                                                                                deleteCard(payment.id);
                                                                                            }
                                                                                        } >Delete</button>
                                                                                    </div>
                                                                                    <div className='Card-Exp-date'>
                                                                                        <p>{`Card expires at ${getcardtype(payment.card_exp)}`}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Transaction">
                                        <div className="transaction-History">
                                            <div className="transaction-History-content">
                                                <div className="transaction-History-content-header mb-4">
                                                    <h3>Billing History</h3>
                                                </div>
                                                <Table className='Transacrion-Table'>
                                                    <thead>
                                                        <tr>
                                                            <th>DATE</th>
                                                            <th>DETAILS</th>
                                                            <th>AMOUNT</th>
                                                            <th>DOWNLOAD</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>02/01/2023</td>
                                                            <td>Window Cleaning, House Cleaning</td>
                                                            <td className='Price-bold'>$2000</td>
                                                            <td><button className='invoice-download'>Invoice 02 Jun 2022</button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>02/01/2023</td>
                                                            <td>Window Cleaning, House Cleaning</td>
                                                            <td className='Price-bold'>$2000</td>
                                                            <td><button className='invoice-download'>Invoice 02 Jun 2022</button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>02/01/2023</td>
                                                            <td>Window Cleaning, House Cleaning</td>
                                                            <td className='Price-bold'>$2000</td>
                                                            <td><button className='invoice-download'>Invoice 02 Jun 2022</button></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Profile" className="hide-desktop show-mobile">
                                        <Profile2 />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    );
}

export default Dashboard;
