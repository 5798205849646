import React, { createContext, useState } from "react";

export const BookingContext = createContext();

export const BookingProvider = (props) => {

    //0 = new user
    //1 = existing user
    const [usertype, setUsertype] = useState(0);
    const [chooseservice, setChooseservice] = useState([]);
    const [choosesubservice, setChoosesubservice] = useState([]);
    const [chooseaddon, setChooseaddon] = useState([]);
    const [choosetechnician, setChoosetechnician] = useState([]);
    const [choosedate, setChoosedate] = useState([]);
    const [choosetime, setChoosetime] = useState([]);
    const [endchoosetime, setEndchoosetime] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [Totalpayment , setTotalpayment] = useState(0);
    const [totalDuration , setTotalDuration] = useState(0);
    const [appointmentNotes, setAppointmentNotes] = useState([]);
    const [servicename , setServicename] = useState("");
    const [confirmpassword , setConfirmpassword] = useState("");


    return (
        <BookingContext.Provider value={[usertype, setUsertype,chooseservice,setChooseservice,choosesubservice, setChoosesubservice,chooseaddon, setChooseaddon,choosetechnician, setChoosetechnician,choosedate, setChoosedate,choosetime, setChoosetime,userDetails, setUserDetails,paymentDetails, setPaymentDetails,Totalpayment , setTotalpayment,totalDuration , setTotalDuration,appointmentNotes, setAppointmentNotes,servicename , setServicename,endchoosetime, setEndchoosetime,confirmpassword , setConfirmpassword]}>
            {props.children}
        </BookingContext.Provider>
    );
};
