import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import { FaStar } from 'react-icons/fa';
import './assets/css/Service.css'
import Header2 from "./camponent/header/Header2";

function WindowCleaning() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="windowcleaning-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Window Cleaning <span className='bold-title'><span className='bold-title bg-color'>Services</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="filter-data-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Window Cleaning Service</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Our local technicians are here and ready to do all your cleaning for you. With our simple 4-step cleaning process, all streak-free window cleaning is done without you having to lift a finger. The only thing you need to do is sit back and relax.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Simple 4-Step Window Cleaning Process</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We start by removing any screens, brushing off any access dirt, and vacuuming out the tracks.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We start by removing any screens, brushing off any access dirt, and vacuuming out the tracks.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We start by removing any screens, brushing off any access dirt, and vacuuming out the tracks.</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>We start by removing any screens, brushing off any access dirt, and vacuuming out the tracks.</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Products We Use</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Dawn</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Winsol</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Unger Easy Glide</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Squeegee</p>
                                        <p className="icon-warper"><span className="windowcleaning-icon"><FaStar /></span>Microfiber Cloth</p>
                                    </div>
                                </div>
                                <div className="windowcleaning-comtent">
                                    <div className="windowcleaning-comtent-title">
                                        <h2 className="title">Why Choose Green Frog</h2>
                                    </div>
                                    <div className="windowcleaning-comtent-text">
                                        <p>Here at Green Frog we are a fully licensed, bonded, and insured, company and we only hire the best of the best. And unlike some companies, we actually care about what we do. So when it comes to your window cleaning needs, contact us today to speak with one of our specialists and see how we can help.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default WindowCleaning;