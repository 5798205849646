import axios from "axios";
import { API_URL } from "./config.js";
import { apiCall } from "./auth";

//getpaymentMethod 
export const getpaymentMethod = (formData) => apiCall(`${API_URL}getpaymentMethod`, "POST", formData);

//getpaymentMethod 
export const deletepaymentmethod = (formData) => apiCall(`${API_URL}deletepaymentmethod`, "POST", formData);

//getpaymentMethod 
export const getstaffApi = () => apiCall(`${API_URL}getstaff`, "GET", []);

//getpaymentMethod 
export const cancelbookingbyid = (formData) => apiCall(`${API_URL}cancelbookingbyid`, "POST", formData);

//getpaymentMethod 
export const updateuserprofile = (formData) => apiCall(`${API_URL}updateuserprofile`, "POST", formData);
