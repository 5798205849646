import React, { useContext, useEffect } from "react";
import '../../assets/css/Booking.css';
import { BookingContext } from "../BookingForm/BookingContext";
import { min } from "moment";

function Totalpayment() {

    const [usertype, setUsertype,chooseservice,setChooseservice,choosesubservice, setChoosesubservice,chooseaddon, setChooseaddon,choosetechnician, setChoosetechnician,choosedate, setChoosedate,choosetime, setChoosetime,userDetails, setUserDetails,paymentDetails, setPaymentDetails,Totalpayment , setTotalpayment,totalDuration , setTotalDuration,appointmentNotes, setAppointmentNotes,servicename , setServicename,endchoosetime, setEndchoosetime] = useContext(BookingContext);


    const getEndtime = (time) => {
        var d = new Date(time);
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;

        var totalMinutes = parseInt(totalDuration);
        var totalHours = parseInt(totalMinutes/60);
        var totalMinutes = parseInt(totalMinutes%60);
        var totalHours = parseInt(totalHours%12);
        var totalHours = parseInt(totalHours ? totalHours : 12);
        // var totalMinutes = parseInt(totalMinutes < 10 ? '0'+totalMinutes : totalMinutes);
        var totalMinutes = totalMinutes < 10 ? '0'+totalMinutes : totalMinutes;
        // var totalHours = parseInt(totalHours < 10 ? '0'+totalHours : totalHours);
        var totalHours = totalHours < 10 ? '0'+totalHours : totalHours;
        var strTime = totalHours + ':' + totalMinutes + ' ' + ampm;
        setEndchoosetime(strTime);
    }



    useEffect(() => {
        getEndtime(choosetime);
    }, [totalDuration]);

    const getDate = (date) => {
        var d = new Date(date);
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var dayName = days[d.getDay()];
        var monthName = d.toLocaleString('default', { month: 'short' });
        var date = d.getDate();
        var year = d.getFullYear();
        var fullDate = dayName + " " + date + " " + monthName + " " + year;
        return fullDate;
    }

    const getTime = (time) => {
        var d = new Date(time);
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        hours = hours < 10 ? '0'+hours : hours;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }




    useEffect(() => {
    }, [chooseservice]);   


    return (
        <div>
            <div className="booking-card">
                <div className="booking-card-header-title">Booking Summary</div>
                <div className="booking-card-header">
                    <div className="booking-left">
                        <div className="booking-card-title">Booking Start</div>
                        <div className="booking-card-subtitle">{getDate(choosedate) || ""}</div>
                        <div className="booking-card-time">{getTime(choosetime) || "12:00 AM"}</div>
                    </div>
                    <div className="booking-right">
                        <div className="booking-card-title">Booking End</div>
                        <div className="booking-card-subtitle">{getDate(choosedate) || ""}</div>
                        <div className="booking-card-time">{endchoosetime || ""}</div>
                    </div>
                </div>
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="booking-card-body-item-title">Cart Items-:</div>
                        <div className="booking-card-service-name">{servicename || ""}</div>
                        {
                            choosesubservice && choosesubservice.map((item) => {
                                return (
                                    <div className="multi-service">
                                        <div className="booking-card-service-name">{item.units_title}</div>
                                        <div className="booking-card-service-name">{item.quantity}</div>
                                    </div>
                                )
                            }
                            )
                        }
                        {
                            chooseaddon && chooseaddon.map((item) => {
                                {
                                    if (item.quantity != 0) {
                                        return (
                                            <div className="multi-service">
                                                <div className="booking-card-service-name">{item.addon_service_name}</div>
                                                <div className="booking-card-service-name">{item.quantity}</div>
                                            </div>

                                        )
                                    }
                                }

                            }
                            )

                        }
                    </div>
                </div>
                <hr />
                <div className="booking-card-footer">
                    <div className="booking-card-footer-item">
                        <div className="booking-card-footer-item-title">Your Price Summary</div>
                        <div className="sub-total">
                            <div className="booking-card-footer-item-subtitle">Sub Total</div>
                            <div className="booking-card-footer-item-price">${Totalpayment || ""}</div>
                        </div>
                        <div className="sub-total discount">
                            <div className="booking-card-footer-item-subtitle">Discount</div>
                            <div className="booking-card-footer-item-price">$ 0</div>
                        </div>
                        <div className="sub-total total-payment">
                            <div className="booking-card-footer-item-subtitle">Total</div>
                            <div className="booking-card-footer-item-price">${Totalpayment || ""}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Totalpayment;