import Carousel from 'react-bootstrap/Carousel';
import './slider.css';
import User from '../../assets/Images/hero/profile.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

function Slider2() {
    return (
        <Carousel>
            <Carousel.Item interval={4000}>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={300}>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
                <div className='slider-content'>
                    <div className='slider-content-view d-flex align-items-center'>
                        <div>
                            <div className='slider-icon'>
                                <FontAwesomeIcon icon={faQuoteLeft} />
                            </div>
                            <div className='slider-text'>
                                <p>Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.</p>
                            </div>
                            <div className='slider-name'>
                                <p className='user-name'>John Doe</p>
                            </div>
                        </div>
                        <div className='slider-image'>
                            <img src={User} alt='slider' className='user-image' />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default Slider2;