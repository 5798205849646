import React, { useState, useContext } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Form from 'react-bootstrap/Form';
import '../../assets/css/Booking.css';
import 'react-notifications/lib/notifications.css';
import { SignUp, getuser } from "../../Api/frontbooingApi";
import { BookingContext } from "./BookingContext";
import { LoginContext } from "../../LoginContext";
import { TbListCheck } from "react-icons/tb";

const Step3 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime,confirmpassword , setConfirmpassword] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [existingemail, setExistingemail] = useState("")
    const [existingpassword, setExistingpassword] = useState("")
    const [buttonState, setButtonState] = useState(0)

    if (LoggedInData != null && buttonState == 0) {
        setUserDetails(LoggedInData);
        setButtonState(1);
    }

    console.log("userDetails", userDetails);
    console.log("LoggedInData", LoggedInData);

    const getUserDetails = () => {
        const formData = new FormData();
        formData.append("email", existingemail);
        formData.append("password", existingpassword);
        getuser(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setUserDetails(apiResponse.data);
                    login(apiResponse.data);
                    setButtonState(1);
                }
                else {
                    NotificationManager.error('Invalid email and password', 'Close after 3000ms', 3000);
                }

            } else {
                alert("Something went wrong");
            }
        });
    };

    const states = [
        { name: 'Alabama', cities: ['Birmingham', 'Montgomery', 'Mobile'] },
        { name: 'Alaska', cities: ['Anchorage', 'Fairbanks', 'Juneau'] },
        { name: 'Arizona', cities: ['Phoenix', 'Tucson', 'Mesa'] },
        { name: 'Arkansas', cities: ['Little Rock', 'Fort Smith', 'Fayetteville'] },
        { name: 'California', cities: ['Los Angeles', 'San Diego', 'San Jose'] },
        { name: 'Colorado', cities: ['Denver', 'Colorado Springs', 'Aurora'] },
        { name: 'Connecticut', cities: ['Bridgeport', 'New Haven', 'Hartford'] },
        { name: 'Delaware', cities: ['Wilmington', 'Dover', 'Newark'] },
        { name: 'Florida', cities: ['Jacksonville', 'Miami', 'Tampa'] },
        { name: 'Georgia', cities: ['Atlanta', 'Augusta', 'Columbus'] },
        { name: 'Hawaii', cities: ['Honolulu', 'Pearl City', 'Hilo'] },
        { name: 'Idaho', cities: ['Boise', 'Nampa', 'Meridian'] },
        { name: 'Illinois', cities: ['Chicago', 'Aurora', 'Rockford'] },
        { name: 'Indiana', cities: ['Indianapolis', 'Fort Wayne', 'Evansville'] },
        { name: 'Iowa', cities: ['Des Moines', 'Cedar Rapids', 'Davenport'] },
        { name: 'Kansas', cities: ['Wichita', 'Overland Park', 'Kansas City'] },
        { name: 'Kentucky', cities: ['Louisville', 'Lexington', 'Bowling Green'] },
        { name: 'Louisiana', cities: ['New Orleans', 'Baton Rouge', 'Shreveport'] },
        { name: 'Maine', cities: ['Portland', 'Lewiston', 'Bangor'] },
        { name: 'Maryland', cities: ['Baltimore', 'Frederick', 'Rockville'] },
        { name: 'Massachusetts', cities: ['Boston', 'Worcester', 'Springfield'] },
        { name: 'Michigan', cities: ['Detroit', 'Grand Rapids', 'Warren'] },
        { name: 'Minnesota', cities: ['Minneapolis', 'Saint Paul', 'Rochester'] },
        { name: 'Mississippi', cities: ['Jackson', 'Gulfport', 'Southaven'] },
        { name: 'Missouri', cities: ['Kansas City', 'Saint Louis', 'Springfield'] },
        { name: 'Montana', cities: ['Billings', 'Missoula', 'Great Falls'] },
        { name: 'Nebraska', cities: ['Omaha', 'Lincoln', 'Bellevue'] },
        { name: 'Nevada', cities: ['Las Vegas', 'Henderson', 'Reno'] },
        { name: 'New Hampshire', cities: ['Manchester', 'Nashua', 'Concord'] },
        { name: 'New Jersey', cities: ['Newark', 'Jersey City', 'Paterson'] },
        { name: 'New Mexico', cities: ['Albuquerque', 'Las Cruces', 'Rio Rancho'] },
        { name: 'New York', cities: ['New York', 'Buffalo', 'Rochester'] },
        { name: 'North Carolina', cities: ['Charlotte', 'Raleigh', 'Greensboro'] },
        { name: 'North Dakota', cities: ['Fargo', 'Bismarck', 'Grand Forks'] },
        { name: 'Ohio', cities: ['Columbus', 'Cleveland', 'Cincinnati'] },
        { name: 'Oklahoma', cities: ['Oklahoma City', 'Tulsa', 'Norman'] },
        { name: 'Oregon', cities: ['Portland', 'Salem', 'Eugene'] },
    ];

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setUserDetails({ ...userDetails, state: event.target.value });
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setUserDetails({ ...userDetails, city: event.target.value });
    };

    const cities = selectedState ? states.find((state) => state.name === selectedState).cities : [];

    if (props.currentStep !== 3) {
        return null;
    }
    return (
        <>
            <div className="booking-form-box">
                {
                    //check LoggedInData is null or not
                    LoggedInData == null ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="step-count">
                                    <div className="step-count-inner">
                                        <div className="Step-icon">
                                            <span><TbListCheck /></span>
                                        </div>
                                        <div className="step-count-inner-title">
                                            Your Account Details
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {usertype == 0 ?
                                    <div className="booking-acount-detail-form">
                                        <div className="row booking-30">
                                            <div className="col-md-12 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Email</Form.Label>
                                                    <Form.Control type="email" value={userDetails.user_email || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, user_email: e.target.value });
                                                        }
                                                    } placeholder="Enter email" className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Password</Form.Label>
                                                    <Form.Control type="password" placeholder="********" value={userDetails.password || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, password: e.target.value });
                                                        }
                                                    }
                                                        className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control type="password" placeholder="********" value={confirmpassword || ""} onChange={
                                                        (e) => {
                                                            setConfirmpassword(e.target.value);
                                                        }
                                                    }
                                                        className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="name" value={userDetails.first_name || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, first_name: e.target.value });
                                                        }
                                                    } placeholder="" className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="name" placeholder="" value={userDetails.last_name || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, last_name: e.target.value });
                                                        }
                                                    } className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control type="number" value={userDetails.phone || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, phone: e.target.value });
                                                        }
                                                    } placeholder="" className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Zip Code</Form.Label>
                                                    <Form.Control type="number" placeholder="" value={userDetails.zip || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, zip: e.target.value });
                                                        }
                                                    } className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Street Address</Form.Label>
                                                    <Form.Control type="text" placeholder="" value={userDetails.address || ""} onChange={
                                                        (e) => {
                                                            setUserDetails({ ...userDetails, address: e.target.value });
                                                        }
                                                    } className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <Form.Group
                                                    className="booking-custom-lable"
                                                    controlId="validationCustom010"
                                                >
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="custom-select-box"
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            handleStateChange(e);
                                                        }}
                                                    >
                                                        <option>Select State</option>

                                                        {states.map((state) => (
                                                            <option value={state.name}>{state.name}</option>
                                                        ))}

                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group
                                                    className="booking-custom-lable"
                                                    controlId="validationCustom011"
                                                    onChange={(e) => {
                                                        handleCityChange(e);
                                                    }}
                                                >
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Select
                                                        required
                                                        aria-label="Default select example"
                                                        className="custom-select-box"
                                                    >
                                                        <option>Select City</option>
                                                        {cities.map((city) => (
                                                            <option key={city} value={city}>
                                                                {city}
                                                            </option>
                                                        ))}

                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            {/* <div className="col-md-12 mb-3">
                                                <div className="booking-custom-lable">
                                                    <button className="wizard-btn btn btn-primary d-flex float-right" style={{ "float": "right", }} onClick={UserSignUp}>SignUp</button>
                                                </div>
                                            </div> */}
                                            <div className="col-md-12 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Appointment Notes</Form.Label>
                                                    <Form.Control type="text" placeholder="" value={appointmentNotes || ""} onChange={
                                                        (e) => {
                                                            setAppointmentNotes(e.target.value);
                                                        }
                                                    } className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="booking-acount-detail-form">
                                        <div className="row booking-30">
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setExistingemail(e.target.value) }} value={existingemail} className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Password</Form.Label>
                                                    <Form.Control type="password" placeholder="********" onChange={(e) => { setExistingpassword(e.target.value) }} value={existingpassword} className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="booking-custom-lable">
                                                    <button className="wizard-btn btn btn-primary d-flex float-right" style={{ "float": "right", }} onClick={getUserDetails}>Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className="row Service-details-box-inner-title">
                            <div className="col-md-12 mb-3">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Appointment Notes</Form.Label>
                                    <Form.Control type="text" placeholder="" value={appointmentNotes || ""} onChange={
                                        (e) => {
                                            setAppointmentNotes(e.target.value);
                                        }
                                    } className="booking-custom-input" />
                                </Form.Group>
                            </div>
                        </div>
                }
            </div>
            <NotificationContainer />
        </>

    );

};

export default Step3;
