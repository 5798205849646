import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Home';
import About from './Aboutus';
import Housecleaning from './HouseCleaning';
import WindowCleaning from './WindowCleaning';
import CleaningTip from './CleaningTip';
import Reviews from './Review';
import InitalCleaning from './InitalCleaning';
import Scheduled from './Scheduled';
import MoveInOut from './MoveIn';
import LaMase from './LaMase';
import SanDiego from './SanDiego';
import CheckList from './CheckList';
import Booking from './Booking';
import Step1 from './camponent/BookingForm/Step01';
import Step2 from './camponent/BookingForm/Step02';
import Step3 from './camponent/BookingForm/Step03';
import Ourproduct from './OurProduct';
import Contactus from './Contactus';
import Dashboard from './Innerpages/Dashboard/Dashboard';
import BookingMain from './camponent/BookingForm/BookingMain';
import { LoginProvider } from './LoginContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LoginProvider>
  <Router>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/about' component={About} />
      <Route path='/Housecleaning' component={Housecleaning} />
      <Route path='/WindowCleaning' component={WindowCleaning} />
      <Route path='/CleaningTip' component={CleaningTip} />
      <Route path='/Reviews' component={Reviews} />
      <Route path='/InitalCleaning' component={InitalCleaning} />
      <Route path='/Ourproduct' component={Ourproduct} />
      <Route path='/Scheduled' component={Scheduled} />
      <Route path='/MoveInOut' component={MoveInOut} />
      <Route path='/LaMase' component={LaMase} />
      <Route path='/SanDiego' component={SanDiego} />
      <Route path='/CheckList' component={CheckList} />
      <Route path='/Booking' component={Booking} />
      <Route path='/Step1' component={Step1} />
      <Route path='/Step2' component={Step2} />
      <Route path='/Step3' component={Step3} />
      <Route path='/contact' component={Contactus} />
      <Route path='/Dashboard' component={Dashboard} />
    </Switch>
  </Router>
  </LoginProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
