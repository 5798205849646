import React, { useState, useContext } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import "./Header2.css";
import Logo from "../../assets/Images/logo/Logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Popuplogo from "../../assets/Images/logo/popuplogo.png";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { LoginContext } from "../../LoginContext";
import { getuser } from "../../Api/frontbooingApi";

const Header2 = () => {
  const { LoggedInData, login, logout } = useContext(LoginContext);

  console.log("LoggedInData", LoggedInData);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     // const form = event.currentTarget;
//     // if (form.checkValidity() === false) {
//     //   event.preventDefault();
//     //   event.stopPropagation();
//     // }
//     // setValidated(true);

//     console.log("email", email); 
//     console.log("password", password); 


//     getUserDetails();

//   };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    getuser(formData).then((res) => {
        if (res.code === 200) {
            if (res.data.status == '1') {
                const apiResponse = res.data;
                login(apiResponse.data);
                handleClose();
            }
            else {
                NotificationManager.error('Invalid email and password');
            }

        } else {
            alert("Something went wrong");
        }
    });
};


  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const toggle = () => {
    document.querySelector(".menu-icon").classList.toggle("active");
    setCollapse(!collapse);
  };

  const [collapse, setCollapse] = useState(false);
  const addClass = () => {
    if (addClass) {
      document.querySelector(".stop").classList.add("new-class-name");
    } else {
      document
        .querySelector(".sidebar-container")
        .classList.remove("new-class-name");
      document.querySelector(".stop").classList.remove("new-class-name");
    }
  };

  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <>
      <nav className="navbar cstm-navbar">
        <h1 className="navbar-logo">
          <Link to={"/"} className="navbar-logo">
            <img src={Logo} alt="Google" className="logo-image" />
          </Link>
        </h1>
        <div onClick={toggle}>
          <div className="menu-icon" onClick={handleClick}>
            <FaBars className={active} />
            {/* <i className={active ? "fas fa-times" : "fas fa-bars"}></i> */}
          </div>
        </div>
        <ul
          className={
            active
              ? "nav-menu custom-header-nav-menu active"
              : "nav-menu custom-header-nav-menu"
          }
        >
          <li className={splitLocation[1] === "" ? "active" : ""}>
            <a href="/" className="nav-links">
              Home
            </a>
          </li>
          <li className={splitLocation[1] === "About" ? "active" : ""}>
            <a href="/About" className="nav-links">
              About Us
            </a>
          </li>
          <li className="nav-item dropdown">
            <Dropdown className="header-dropdwn">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Services
              </Dropdown.Toggle>

              <Dropdown.Menu className="home-drop-show">
                <Dropdown.Item className="hover-new" href="/Housecleaning">
                  House Cleaning Services
                </Dropdown.Item>
                <Dropdown.Item className="hover-new" href="/WindowCleaning">
                  Window Cleaning Services
                </Dropdown.Item>
                <Dropdown.Item className="hover-new" href="/CleaningTip">
                  House Cleaning Tips
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className={splitLocation[1] === "ourProduct" ? "active" : ""}>
            <a href="/ourProduct" className="nav-links">
              Our Product
            </a>
          </li>
          <li className={splitLocation[1] === "Reviews" ? "active" : ""}>
            <a href="/Reviews" className="nav-links">
              Review
            </a>
          </li>
          <li className={splitLocation[1] === "Contact" ? "active" : ""}>
            <a href="/Contact" className="nav-links">
              Contact
            </a>
          </li>
          { LoggedInData == null ? (
            <li>
               <Button
                className="nav-links header-login-btn"
                onClick={handleShow}
              >
                Sign in
              </Button>
            </li>
          ) : (
            <li>
            
              <Button className="nav-links header-login-btn" onClick={logout}>
                Logout
              </Button>
            </li>
          )}

          {/* <li>
                        <Button className='nav-links header-signup-btn'>
                            Get a quote
                        </Button>
                    </li> */}
        </ul>
      </nav>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div className="modal-nav">
            <div className="modal-logo">
              <img src={Popuplogo} alt="logo" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-tltle">Sign in to your Account</div>
          <div className="form-section">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className="custom-lable">Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="Enter email"
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your name
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className="custom-lable">Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Password"
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email
                </Form.Control.Feedback>
              </Form.Group>
                <Button className="Login-btn-view" type="submit">
                  Submit
                </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </>
  );
};

export default Header2;
