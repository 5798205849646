import React from "react";
import { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import Profile from '../../assets/Images/technician.png';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../BookingForm/components/Calender/Calender.css';
import '../../assets/css/Booking.css';
import moment from "moment";
import { getstafflist } from "../../Api/frontbooingApi";
import { getstaffTime } from "../../Api/frontbooingApi";
import { BookingContext } from "./BookingContext";
import { TbListCheck } from "react-icons/tb";

const Step2 = props => {

    const [usertype, setUsertype,chooseservice,setChooseservice,choosesubservice, setChoosesubservice,chooseaddon, setChooseaddon,choosetechnician, setChoosetechnician,choosedate, setChoosedate,choosetime, setChoosetime,userDetails, setUserDetails,paymentDetails, setPaymentDetails,Totalpayment , setTotalpayment,totalDuration , setTotalDuration,appointmentNotes, setAppointmentNotes,servicename , setServicename,endchoosetime, setEndchoosetime,confirmpassword , setConfirmpassword] = useContext(BookingContext);

    const [stafflist, setStaffList] = useState([]);
    const [staffid, setStaffid] = useState(0);
    const [value, onChange] = useState(new Date());
    const [availableTime, setavailableTime] = useState([]);
    const [selectedSlotid, setSelectedSlotid] = useState(0);

    const getstaff = () => {
        getstafflist().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setStaffList(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    useEffect(() => {
        getstaff();
    }, []);

    const selectstaff = (id) => {
        setStaffid(id);
        setChoosetechnician(id)
        document.querySelector(".calenderHideShow").classList.add("active");
    }

    useEffect(() => {
        getstaffavailability(value);
    }, [staffid]);

    const getstaffavailability = (e) => {
        const date = moment(e).format("YYYY-MM-DD");
        setChoosedate(date);
        getstaffTime(date, staffid).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.data.length > 0) {
                    getavailableTimeSlots(apiResponse.data, date);
                } else {
                    getavailableTimeSlots([], date);
                }
            } else {
                alert("Something went wrong");
            }
        });

    }


    if (props.currentStep !== 2) {
        return null;
    }


    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */

    const getavailableTimeSlots = (bookingData, startdate) => {


        const startTime = moment(moment(startdate).set({ hour: 6, minute: 0, second: 0 }));
        const endTime = moment(moment(startdate).set({ hour: 18, minute: 0, second: 0 }));

        const offDaysData = [];

        var counting = 1;

        const availableTimeSlots = [];

        let currentTime = startTime.clone();

        while (currentTime.isSameOrBefore(endTime)) {
            const slotStart = currentTime.format("YYYY-MM-DD HH:mm:ss");
            const slotEnd = currentTime.add(1, "hour").format("YYYY-MM-DD HH:mm:ss");

            const isBooked = bookingData.some((booking) => {
                var className = booking.className.trim();
                if (className == "off_day_class") {
                    var startdatearray = booking.start.split(" ");
                    var enddatearray = booking.end.split(" ");

                    var startdatearraylength = startdatearray.length;


                    if (startdatearraylength > 1) {
                        var bookingStart = moment(booking.start);
                        var bookingEnd = moment(booking.end);
                    }
                    else {
                        var bookingStart = moment(booking.start + " 00:00:00").format("YYYY-MM-DD HH:mm:ss");
                        var bookingEnd = moment(booking.end + " 23:59:59").format("YYYY-MM-DD HH:mm:ss");
                    }

                }
                else {
                    var bookingStart = moment(booking.start);
                    var bookingEnd = moment(booking.end);
                }
                return (
                    currentTime.isSameOrAfter(bookingStart) &&
                    currentTime.isBefore(bookingEnd)
                );
            });

            const isOffDay = offDaysData.some((offDay) => {
                const offDayStart = moment(offDay.start);
                const offDayEnd = moment(offDay.end);
                return (
                    currentTime.isSameOrAfter(offDayStart) && currentTime.isBefore(offDayEnd)
                );
            });

            if (!isBooked && !isOffDay) {
                availableTimeSlots.push({
                    id: counting++,
                    start: slotStart,
                    end: slotEnd,
                });
            }
        }

        setavailableTime(availableTimeSlots);

    }

    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */

    // addactiveclass = (e) => {









    return (
        <>
            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="step-count">
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    Technician
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="technician-list">
                            {stafflist && stafflist.map((item, index) => (

                                <Button className={`technician-profile-btn ${staffid === item.id ? 'active' : ''}`} onClick={(e) => {
                                    selectstaff(item.id);
                                }}>
                                    <div className="technician-profile">
                                        <div className="technician-profile-img">
                                            {item.image ? <img src={item.image} alt="Avatar" /> : <img src={Profile} alt="Avatar" />
                                            }
                                        </div>
                                        <div className="technician-profile-info">
                                            <div className="technician-profile-name">
                                                <h4>{item.fullname}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row booking-warrper-40" {
                    ...choosetechnician === 0 ? { style: { display: "none" } } : { style: { display: "block" } }
                }>
                    <div className="col-md-12" {...choosetechnician === 0 ? { style: { display: "none" } } : { style: { display: "block" } }}>
                        <div className="step-count">
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    When would you like us to come?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="calender-box">
                                <Calendar onChange={(e) => { onChange(e); getstaffavailability(e); }} value={value} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="time-box">
                                <div className="time-box-inner">
                                    <div className="time-list">
                                        {availableTime && availableTime.map((item, index) => (

                                            <Button className={`time-btn ${selectedSlotid === item.id ? "active" : ""}`} onClick={
                                                (e) => {
                                                    setChoosetime(item.start);
                                                    setSelectedSlotid(item.id);
                                                }
                                            } >
                                                <div className="time-btn-inner">
                                                    <div className="time-btn-inner-title">
                                                        {moment(item.start).format("hh:mm A")}
                                                        {/* {moment(item.start).format("hh:mm A")} - {moment(item.end).format("hh:mm A")} */}
                                                    </div>
                                                </div>
                                            </Button>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Step2;
