import React, { useState, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { TbListCheck } from "react-icons/tb";
import { BookingContext } from "./BookingContext";
import { LoginContext } from "../../LoginContext";

const Step4 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);


    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [personName, setPersonName] = useState("");

    const validateCardNumber = (value) => {
        let error;
        if (!value) {
            error = 'Please enter your card number.';
        } else if (!/^\d{12,19}$/.test(value)) {
            error = 'Please enter a valid card number.';
        } else if (!isValidLuhn(value)) {
            error = 'The card number that you entered is invalid.';
        }
        return error;
    };

    const handleCardNumberChange = (e) => {
        const input = e.target.value.replace(/\s/g, ''); // Remove whitespace
        if (input.length > 16) {
            return;
        }
        setCardNumber(input);

        setPaymentDetails({
            ...paymentDetails,
            cardNumber: input,
        });
    };

    const handleExpiryDateChange = (e) => {

        //set expiry date like 10 / 20
        const input = e.target.value.replace(/\s/g, '').replace(/[^0-9]/gi, ''); // Remove whitespace and non-numeric
        if (input.length > 4) {
            return;
        }
        const month = input.slice(0, 2);
        const year = input.slice(2, 4);
        const expiryDate = `${month}/${year}`;

        setExpiryDate(e.target.value);

        //set setPaymentDetails
        setPaymentDetails({
            ...paymentDetails,
            expiryDate: expiryDate,
        });
    };

    const handleCvvChange = (e) => {
        //set cvv like 1232
        const input = e.target.value.replace(/\s/g, '').replace(/[^0-9]/gi, ''); // Remove whitespace and non-numeric
        if (input.length > 4) {
            return;
        }
        setCvv(e.target.value);

        setPaymentDetails({
            ...paymentDetails,
            cvv: input,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValidCardNumber = /^\d{12,19}$/.test(cardNumber) && isValidLuhn(cardNumber);

        const isValidExpiryDate = /^(0[1-9]|1[0-2])\/\d{2}$/.test(expiryDate);

        const isValidCvv = /^[0-9]{3,4}$/.test(cvv);


        if (isValidCardNumber && isValidExpiryDate && isValidCvv) {
            // Payment form is valid
            console.log('Payment form submitted successfully.');
        } else {
            // Display error messages or take appropriate action
            console.log('Payment form contains invalid fields.');
        }
    };

    const handlesetPersonName = (e) => {
        setPersonName(e.target.value);
        setPaymentDetails({
            ...paymentDetails,
            personName: e.target.value,
        });
    }



    const isValidLuhn = (value) => {
        let sum = 0;
        let shouldDouble = false;

        // Iterate from right to left
        for (let i = value.length - 1; i >= 0; i--) {
            let digit = parseInt(value.charAt(i), 10);

            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    };




    if (props.currentStep !== 4) {
        return null;
    }

    return (
        <>
            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="step-count" >
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    Fattmerchant Payment
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="booking-acount-detail-form">
                            <div className="row booking-30">
                                <div className="col-md-12 mb-3">
                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                        <Form.Label>Card Number</Form.Label>
                                        <Form.Control type="number" placeholder="7563 4592 4863 9328" autoComplete="off" name="cardNumber" value={cardNumber} onChange={handleCardNumberChange} className="booking-custom-input" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-7 mb-3">
                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="name" placeholder="" value={personName} onChange={handlesetPersonName} className="booking-custom-input" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                        <Form.Label>EXP. Date</Form.Label>
                                        <Form.Control type="number" placeholder="" value={expiryDate} onChange={handleExpiryDateChange} className="booking-custom-input" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2 mb-3">
                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                        <Form.Label>CVV</Form.Label>
                                        <Form.Control type="number" placeholder="" value={cvv} onChange={handleCvvChange} className="booking-custom-input" />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="row booking-30">
                                <div className="col-md-12">
                                    <Form>
                                        <Form.Check aria-label="option 1" label="I have read and accepted the Privacy Policy." />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Step4;
