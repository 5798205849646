import React, { useEffect, useContext ,useState } from "react";
import '../../assets/css/Booking.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Map from "./components/Map/Map";
import { BsCheckCircleFill } from "react-icons/bs";
import { BookingContext } from "./BookingContext";
import { getbookingservices } from "../../Api/frontbooingApi";
import { getsubservicebyservice } from "../../Api/frontbooingApi";
import { getaddon } from "../../Api/frontbooingApi";
import { TbListCheck } from "react-icons/tb";
import { LoginContext } from '../../LoginContext';


const Step1 = props => {

    const [usertype, setUsertype,chooseservice,setChooseservice,choosesubservice, setChoosesubservice,chooseaddon, setChooseaddon,choosetechnician, setChoosetechnician,choosedate, setChoosedate,choosetime, setChoosetime,userDetails, setUserDetails,paymentDetails, setPaymentDetails,Totalpayment , setTotalpayment,totalDuration , setTotalDuration,appointmentNotes, setAppointmentNotes,servicename , setServicename,endchoosetime, setEndchoosetime,confirmpassword , setConfirmpassword] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [serviceList, setServiceList] = React.useState([]);
    const [serviceactive, setServiceactive] = React.useState(0);

    useEffect(() => {
        getServiceList();
    }, []);


    useEffect(() => {
        if (LoggedInData == null) {
            setUsertype(0);
        } else {
            setUsertype(1);
        }
    }, [LoggedInData]);

    useEffect(() => {
        if (chooseservice === 0) {
            setChoosesubservice([]);
            setChooseaddon([]);
        }

    }, []);

    const addStyle = () => {
        document.querySelector('.display-view-item').style.display = "block";
    }

    const newUser = () => {
        document.querySelector('.select-box').classList.add('active');
        document.querySelector('.select-box2').classList.remove('active');
        document.querySelector('.display-view-item').style.display = "none";
        setUsertype(0);
    }

    const existingUser = () => {
        document.querySelector('.select-box').classList.remove('active');
        document.querySelector('.select-box2').classList.add('active');
        setUsertype(1);
    }

    const getServiceList = () => {
        getbookingservices().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setServiceList(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getsubservice = (service) => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("service_id", service);
        getsubservicebyservice(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                // setSubService(apiResponse.data);
                setChoosesubservice(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getaddonsservice = (service) => {
        const formData = new FormData();
        formData.append("service_id", service);
        getaddon(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setChooseaddon(apiResponse.data);
                }
                else {
                    setChooseaddon([]);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };

    function changeCounter2(value, id) {
        if (value === "increment") {
            const updatedArray = choosesubservice.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) + 1,
                    };
                }

                return item;
            });
            setChoosesubservice(updatedArray);
        } else if (value === "decrement") {
            const updatedArray = choosesubservice.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) - 1,
                    };
                }

                return item;
            });
            setChoosesubservice(updatedArray);
        }
    }

    function changeAddon(value, id) {
        if (value === "increment") {
            const updatedArray = chooseaddon.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) + 1,
                    };
                }

                return item;
            });

            setChooseaddon(updatedArray);
        } else if (value === "decrement") {
            const updatedArray = chooseaddon.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) - 1,
                    };
                }

                return item;
            });
            setChooseaddon(updatedArray);
        }
    }

    const setserviceselection = (e) => {
        console.log("e", e.target);
        //set selected value = 1 or e.target.value in serviceList array
        const updatedArray = serviceList.map((item) => {
            if (item.id == parseInt(e.target.value)) {
                return {
                    ...item,
                    selected: 1,
                };
            } else {
                return {
                    ...item,
                    selected: 0,
                };
            }
        });
        setServiceList(updatedArray);


        //get s

        getsubservice(e.target.value);
        getaddonsservice(e.target.value);
        setChooseservice(e.target.value);
        setServiceactive(1);
    }

    useEffect(() => {
        calculatePrice();
    }, [choosesubservice, chooseaddon]);

    const calculatePrice = () => {
        var totalAmountvar = 0;
        var totalDurationvar = 0;
        choosesubservice.map((item) => {
            const basePrice = parseInt(item.base_price);
            const baseCount = 1;
            const baseDuration = parseInt(item.base_duration);
            const extraCount = item.quantity - baseCount;
            const extraPrice = parseInt(item.base_price_extra_unit);
            const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
            const baseAmount = basePrice * baseCount;
            const extraAmount = extraPrice * extraCount;
            totalAmountvar = totalAmountvar + baseAmount + extraAmount;
            totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
        });
        chooseaddon.map((item) => {
            if (item.quantity == 0) {
            }
            else {
                const basePrice = parseInt(item.base_price);
                const baseCount = 1;
                const baseDuration = parseInt(item.base_duration);;
                const extraCount = item.quantity - baseCount;
                const extraPrice = parseInt(item.price_for_per_extra_unit);
                const extraDuration = parseInt(item.extra_minute_per_unit);
                const baseAmount = basePrice * baseCount;
                const extraAmount = extraPrice * extraCount;
                totalAmountvar = totalAmountvar + baseAmount + extraAmount;
                totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
            }

        });

        console.log("totalDurationvartotalDurationvar", totalDurationvar);
        setTotalpayment(totalAmountvar);
        setTotalDuration(totalDurationvar);
    }

    useEffect(() => {
        console.log("serviceList", serviceList);
    }, [serviceList]);

    if (props.currentStep !== 1) {
        return null;
    }






    return (
        <>
            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            {LoggedInData == null ? (
                                <div className="booking-warrper-mb-40">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Choose User
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row booking-warrper-40">
                                        <div className="col-xl-6 col-lg-12 col-sm-12">
                                            <div className="select-box active" onClick={newUser}>
                                                <div className="select-box-inner">
                                                    <div className="select-box-content">
                                                        <div className="select-box-inner-title">
                                                            New User
                                                        </div>
                                                        <div className="select-box-inner-content">
                                                            <p>Create Your Account If You Dont Have Existing One</p>
                                                        </div>
                                                    </div>
                                                    <div className="select-box-icon">
                                                        <BsCheckCircleFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-sm-12">
                                            <div className="select-type-btn" onClick={addStyle}>
                                                <div className="select-box select-box2" onClick={existingUser}>
                                                    <div className="select-box-inner">
                                                        <div className="select-box-content">
                                                            <div className="select-box-inner-title">
                                                                Existing User
                                                            </div>
                                                            <div className="select-box-inner-content">
                                                                <p>Login With Your User Name & Password</p>
                                                            </div>
                                                        </div>
                                                        <div className="select-box-icon">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)}
                            <div className="display-view-item" style={{ display: "none" }}>
                                <div className="use-zip-code">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                <Form.Label>Please Enter Zip Code</Form.Label>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-10">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Enter zipcode" className="booking-custom-input" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="search-btn">
                                                <button type="button" className="btn search-btn-custom">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Map-view booking-warrper-mb-40">
                                    <Map />
                                </div>
                            </div>

                            <div className="hide-content-view">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Choose A Services
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Service-details-box booking-warrper-40">
                                        <div className="row">
                                            <Form>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <div className="Service-details-box-inner" >
                                                            <div className="d-flex align-items-center">
                                                                <div className="selexted-service-check" >
                                                                    {serviceList && serviceList.map((data) => (
                                                                        <Form.Check
                                                                            inline
                                                                            label={data.title}
                                                                            name="group1"
                                                                            type={type}
                                                                            checked={data.selected === 1 ? true : false}
                                                                            id={`inline-${type}-${data.id}`}
                                                                            value={data.id}
                                                                            onClick={(e) => {
                                                                                setserviceselection(e);
                                                                                setServicename(data.title);
                                                                            }}
                                                                            className="Service-details-box-inner-title"
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hide-content-view booking-warrper-40 serviceoption" {...serviceactive == 0 ? { style: { display: "none" } } : { style: { display: "block" } }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Service Options
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="extra-service booking-warrper-40">
                                    <h6 className="extra-service-title">Sub Services</h6>
                                    <div className="row">
                                        {choosesubservice && choosesubservice.map((item) => {
                                            return (
                                                <div className="col-md-12">
                                                    <div className="Service-details-box-inner extra-service">
                                                        <div className="left-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="selexted-service-check selexted-service-check-7">
                                                                    {/* <Form>
                                                                        <Form.Check aria-label="option 7" />
                                                                    </Form> */}
                                                                </div>
                                                                <h3 className="Service-details-box-inner-title">{item.units_title}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="right-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        href="#"
                                                                        className="add-item-btn"
                                                                        disabled={item.quantity === 1 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeCounter2("decrement", item.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        -
                                                                    </Button>
                                                                </div>
                                                                <div className="extra-service-value">
                                                                    {item.quantity}
                                                                </div>
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        href="#"
                                                                        className="add-item-btn"
                                                                        disabled={item.quantity === 6 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeCounter2("increment", item.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="extra-service booking-warrper-40">
                                    <h6 className="extra-service-title">Addons</h6>
                                    <div className="row">
                                        {chooseaddon.map((value, index) => {
                                            return (
                                                <div className="col-md-12">
                                                    <div className="Service-details-box-inner extra-service mb-3">
                                                        <div className="left-side">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="Service-details-box-inner-title">
                                                                    {value.addon_service_name}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="right-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        href="#"
                                                                        className="add-item-btn"
                                                                        disabled={value.quantity == 0 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeAddon("decrement", value.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        -
                                                                    </Button>
                                                                </div>
                                                                <div className="extra-service-value">
                                                                    {value.quantity}
                                                                </div>
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        href="#"
                                                                        className="add-item-btn"
                                                                        disabled={value.quantity == 6 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeAddon("increment", value.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Step1;
