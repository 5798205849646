import React from "react";
import Header from './camponent/header/Header';
import Footer from './camponent/footer/Footer';
import Rightbar from "./camponent/Rightbar";
import Review from './camponent/Reviews/Review';
import Profile from '../src/assets/Images/profile.jpg';
import Header2 from "./camponent/header/Header2";
import Comman from "./camponent/CommanContent/Comman";

function Reviews() {

    return (
        <>
            <div className="HouseCleaning">
                <div className="Header">
                    <Header2 />
                </div>

                {/* hero section start here  */}
                <div className="review-hero custom-section" id="about-hero">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="hero-image">
                                    <div className="hero-text">
                                        <div className="bg-custom-title">
                                            <h2 className='title'>Greenfrog <span className='bold-title'><span className='bold-title bg-color'>Reviews</span></span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* hero section end here  */}

                {/* filter gallery section start here  */}
                <section className="reviews-section custom-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="review-box">
                                    <div className="review-box-inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Consistently great'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-box-inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Consistently great'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review-box-inner">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Consistently great'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                            <div className="col-xl-6 col-lg-12">
                                                <Review clientimage={Profile} clientheadline={'Jalyn is great!'} clientcomment={'Our cleaning technician did a great job and our house is spotless.Our cleaning technician did a great job and our house is spotless.'} clientname={'Ann Franci'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <Rightbar />
                            </div>
                        </div>
                    </div>
                </section>
                {/* filter gallery section end here  */}

                <Comman />

                {/* footer section start here  */}
                <Footer />
                {/* footer section end here  */}
            </div>
        </>

    );

}

export default Reviews;