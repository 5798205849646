import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Profile.css';
import Angi from '../../../assets/Images/social/angi.webp';
import Facebook from '../../../assets/Images/social/facebook.png';
import Nextdoor from '../../../assets/Images/social/nextdoor.webp';
import Yelp from '../../../assets/Images/social/yelp.webp';
import User from '../../../assets/Images/User.webp';
import Userback from '../../../assets/Images/back.webp';
import { BsArrowRepeat } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { LoginContext } from '../../../LoginContext';
import { updateuserprofile } from '../../../Api/dashboardApi';
import { SITE_URL } from '../../../Api/config';

function Profile() {

    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [userData, setUserData] = useState({
        'first_name': '',
        'last_name': '',
        'user_email': '',
        'password': '',
        'phone': '',
        'address': '',
        'city': '',
        'state': '',
        'zip': '',
        'specialInstructions': ''
    })

    const [email, setemail] = useState('');

    useEffect(() => {
        if (LoggedInData != null) {
            setUserData(LoggedInData)
            console.log("fromprofile", LoggedInData);
        }

    }, [LoggedInData]);

    console.log("LoggedInData", LoggedInData);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [validated, setValidated] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value
        }));
    };

    const states = [
        { name: 'Alabama', cities: ['Birmingham', 'Montgomery', 'Mobile'] },
        { name: 'Alaska', cities: ['Anchorage', 'Fairbanks', 'Juneau'] },
        { name: 'Arizona', cities: ['Phoenix', 'Tucson', 'Mesa'] },
        { name: 'Arkansas', cities: ['Little Rock', 'Fort Smith', 'Fayetteville'] },
        { name: 'California', cities: ['Los Angeles', 'San Diego', 'San Jose'] },
        { name: 'Colorado', cities: ['Denver', 'Colorado Springs', 'Aurora'] },
        { name: 'Connecticut', cities: ['Bridgeport', 'New Haven', 'Hartford'] },
        { name: 'Delaware', cities: ['Wilmington', 'Dover', 'Newark'] },
        { name: 'Florida', cities: ['Jacksonville', 'Miami', 'Tampa'] },
        { name: 'Georgia', cities: ['Atlanta', 'Augusta', 'Columbus'] },
        { name: 'Hawaii', cities: ['Honolulu', 'Pearl City', 'Hilo'] },
        { name: 'Idaho', cities: ['Boise', 'Nampa', 'Meridian'] },
        { name: 'Illinois', cities: ['Chicago', 'Aurora', 'Rockford'] },
        { name: 'Indiana', cities: ['Indianapolis', 'Fort Wayne', 'Evansville'] },
        { name: 'Iowa', cities: ['Des Moines', 'Cedar Rapids', 'Davenport'] },
        { name: 'Kansas', cities: ['Wichita', 'Overland Park', 'Kansas City'] },
        { name: 'Kentucky', cities: ['Louisville', 'Lexington', 'Bowling Green'] },
        { name: 'Louisiana', cities: ['New Orleans', 'Baton Rouge', 'Shreveport'] },
        { name: 'Maine', cities: ['Portland', 'Lewiston', 'Bangor'] },
        { name: 'Maryland', cities: ['Baltimore', 'Frederick', 'Rockville'] },
        { name: 'Massachusetts', cities: ['Boston', 'Worcester', 'Springfield'] },
        { name: 'Michigan', cities: ['Detroit', 'Grand Rapids', 'Warren'] },
        { name: 'Minnesota', cities: ['Minneapolis', 'Saint Paul', 'Rochester'] },
        { name: 'Mississippi', cities: ['Jackson', 'Gulfport', 'Southaven'] },
        { name: 'Missouri', cities: ['Kansas City', 'Saint Louis', 'Springfield'] },
        { name: 'Montana', cities: ['Billings', 'Missoula', 'Great Falls'] },
        { name: 'Nebraska', cities: ['Omaha', 'Lincoln', 'Bellevue'] },
        { name: 'Nevada', cities: ['Las Vegas', 'Henderson', 'Reno'] },
        { name: 'New Hampshire', cities: ['Manchester', 'Nashua', 'Concord'] },
        { name: 'New Jersey', cities: ['Newark', 'Jersey City', 'Paterson'] },
        { name: 'New Mexico', cities: ['Albuquerque', 'Las Cruces', 'Rio Rancho'] },
        { name: 'New York', cities: ['New York', 'Buffalo', 'Rochester'] },
        { name: 'North Carolina', cities: ['Charlotte', 'Raleigh', 'Greensboro'] },
        { name: 'North Dakota', cities: ['Fargo', 'Bismarck', 'Grand Forks'] },
        { name: 'Ohio', cities: ['Columbus', 'Cleveland', 'Cincinnati'] },
        { name: 'Oklahoma', cities: ['Oklahoma City', 'Tulsa', 'Norman'] },
        { name: 'Oregon', cities: ['Portland', 'Salem', 'Eugene'] },
    ];

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const cities = selectedState ? states.find((state) => state.name === selectedState).cities : [];

    const handleSubmit = async (event) => {
        event.preventDefault();
    };
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        // setUserDetails({ ...userDetails, state: event.target.value });
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        // setUserDetails({ ...userDetails, city: event.target.value });
    };

    const updateprofile = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('user_id', LoggedInData.id);
        formData.append('first_name', userData.first_name);
        formData.append('last_name', userData.last_name);
        formData.append('user_email', userData.user_email);
        formData.append('phone', userData.phone);
        formData.append('address', userData.address);
        formData.append('city', selectedCity);
        formData.append('state', selectedState);
        formData.append('zip', userData.zip);
        formData.append('special_instructions', userData.special_instructions);

        updateuserprofile(formData).then((res) => {
            console.log("res", res);
            if (res.code == '200') {
                if(res.data.status == '1'){
                    alert("Profile updated successfully");
                    handleClose();
                    const apiResponse = res.data.data;
                    console.log("apiResponse", apiResponse);
                    login(apiResponse);
                }
                
            }
            else {
                alert("Something went wrong");
            }
        });





    }

    useEffect(() => {
        if (userData.state) {
            setSelectedState(userData.state);
            setSelectedCity(userData.city);
        }
    }, [userData]);


    return (

        <>
            {/* Edit Profile Modal start here  */}
            <div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom01">
                                        <Form.Label className='custom-lable'>first name</Form.Label>
                                        <Form.Control required type="name" value={userData.first_name} name="first_name" onChange={(e) => handleChange(e)} placeholder="John" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your first name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom02">
                                        <Form.Label className='custom-lable'>last name</Form.Label>
                                        <Form.Control required type="name" value={userData.last_name} name="last_name" onChange={(e) => handleChange(e)} placeholder="Deo" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your last name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom03">
                                        <Form.Label className='custom-lable'>email address</Form.Label>
                                        <Form.Control required type="email" value={userData.user_email} name="user_email" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your email
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom04">
                                        <Form.Label className='custom-lable'>Number</Form.Label>
                                        <Form.Control required type="number" value={userData.phone} name="phone" onChange={(e) => handleChange(e)} placeholder="457-456-6794" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom05">
                                        <Form.Label className='custom-lable'>Street Address</Form.Label>
                                        <Form.Control required type="text" value={userData.address} name="address" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom08">
                                        <Form.Label className='custom-lable'>State</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            className="custom-select-box"
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                handleStateChange(e);
                                            }}
                                        >
                                            <option value="">Select State</option>
                                            {states.map((state) => (
                                                <option value={state.name} selected={state.name === userData.state}> {state.name} </option>

                                            ))}

                                        </Form.Select>


                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom07">
                                        <Form.Label className='custom-lable'>City</Form.Label>
                                        <Form.Group
                                                    className="booking-custom-lable"
                                                    controlId="validationCustom011"
                                                    onChange={(e) => {
                                                        handleCityChange(e);
                                                    }}
                                                >
                                                    <Form.Select
                                                        aria-label="Default select example"
                                                        className="custom-select-box"
                                                    >
                                                        <option value="">Select City</option>
                                                        {cities.map((city) => (
                                                            <option value={city} selected={city === userData.city}> {city} </option>
                                                        ))}
                                                    </Form.Select>
                                                            

                                                </Form.Group>
                                        {/* <Form.Control required type="text" value={userData.city} name="city" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' /> */}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom06">
                                        <Form.Label className='custom-lable'>Zip Code</Form.Label>
                                        <Form.Control required type="number" value={userData.zip} name="zip" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom09">
                                        <Form.Label className='custom-lable'>Special Instructions</Form.Label>
                                        <Form.Control required type="text" value={userData.special_instructions} name="special_instructions" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='modal-btn'>
                                    <button href="#" className='btn Active-bttn' onClick={updateprofile} >Save Changes</button>
                                    <button href="#" className='btn Cancel-bttn' onClick={handleClose}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Edit Profile Modal End Here  */}


            {/* Change Password Modal Start Here */}
            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Old Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>New Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Confirm Password</Form.Label>
                                    <Form.Control required type="password" value={email} onChange={(e) => setemail(e.target.value)} placeholder="**********" className='custom-input' />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='modal-btn'>
                                <button href="#" className='btn Active-bttn' onClick={handleClose2} >Save Changes</button>
                                <button href="#" className='btn Cancel-bttn' onClick={handleClose2}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Change Password Modal End Here */}

            <div className="App">
                <div className="Profile-card">
                    <div className="Profile-back-cover">
                        <img src={Userback} alt="Rectangle-1" />
                    </div>
                    <div className="Profile-img">
                        <img src={`${SITE_URL}uploads/photo/${LoggedInData && LoggedInData.image}`} alt="Rect" className='img-fluid rounded-circle' />
                    </div>
                    <div className="Profile-name">
                        <h4>{LoggedInData && `${LoggedInData.first_name} ${LoggedInData.last_name} `}</h4>
                    </div>
                    <div className="profile-btn">
                        <button className="btn" onClick={handleShow}> Edit Profile</button>
                        <button className="btn" onClick={handleShow2}> Change Password</button>
                    </div>
                    <div className="Profile-details">
                        <div className="email d-flex">
                            <h6 className='details-width'>Email : </h6>
                            <p>{LoggedInData && LoggedInData.user_email}</p>
                        </div>
                        <div className="phone d-flex">
                            <h6 className='details-width'>Phone : </h6>
                            <p>{LoggedInData && LoggedInData.phone}</p>
                        </div>
                        <div className="address d-flex">
                            <h6 className='details-width'>Zip Code : </h6>
                            <p>{LoggedInData && LoggedInData.zip}</p>
                        </div>
                    </div>
                    <div className="Profile-card-bottom">
                        <div className="profile-bottom-card">
                            <div className="social-images-icon d-flex justify-content-between w-100">
                                <a href='#'><img src={Facebook} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Nextdoor} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Yelp} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Angi} alt="Rectangle-1" /> </a>
                            </div>
                            <div className="bottom-discription">
                                <p> Green Frog © 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Profile;
