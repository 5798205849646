import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './Review.css';

function Review({clientheadline, clientcomment, clientname, clientimage}) {
    return (
        <div className="Reviews">
            <div className="d-flex align-items-center justify-content-between">
                <div className="Reviews-content">
                    <h3>{clientheadline}</h3>
                    <div className="Reviews-Rating">
                        <div className="Reviews-Rating-star">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                    </div>
                    <p>{clientcomment}</p>
                    <h4>{clientname}</h4>
                </div>
                <div className="Reviews-content-img">
                    <img src={clientimage} alt="####" />
                </div>
            </div>
        </div>
    );
}

export default Review;